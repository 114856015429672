<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="8" lg="10" md="10" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Établissement > Super établissement" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail du super établissement</div>
              </v-row>
            </v-card-title>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom digital"
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom  -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom complet</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom complet"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

             <!-- le site web  -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">Site web</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'url du site web"
                    dense
                    v-model="website"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'établissement principal -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">établissement principal</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner un établissement principal"
                    :items="availableMainEstablishments"
                    item-text="digitalName"
                    no-data-text="aucun établissement"
                    v-model="mainEstablishment"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les établissements associés -->
            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular mt-2">
                    les établissements associés
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <!-- Affichage des établissements à associé -->
                  <div v-for="(estab, i) in associateEstablishment" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ estab.digitalName }}
                      </div>
                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateEstablishment(estab)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <!-- Affichage de la liste des établissements associables -->
                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete
                        v-model="selectedEstablishment"
                        :items="availableEstablishment"
                        item-text="digitalName"
                        return-object
                        placeholder="Associer un établissement"
                        hint="Associer un établissement puis appuyer sur le bouton '+'"
                        clearable
                        no-data-text="aucun établissement"
                        v-on:keyup.enter="addEstablishment(selectedEstablishment)"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addEstablishment(selectedEstablishment)"
                        :disabled="!selectedEstablishment"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { SuperEstablishmentService } from "@/service/etablishment/super_establishment_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";

import { areTheSame, trimString } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditSuperEstablishment",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      serviceEstablishment: null,

      /** l'identifiant à éditer */
      entityId: null,

      /**La donnée originale */
      source: null,
      
      /** le nom digital */
      digitalName: null,
      /** le nom */
      name: null,
      /** le nom */
      website: null,
      /** l'établissement principal sélectionné */
      mainEstablishment: null,

      /** La liste des tous les super établissements */
      allSuperEstablisments: [],
      /** La liste des établissement principal sélectionnables */
      allAssociableEstablishments: [],

      /** la liste des établissement associés */
      associateEstablishment: [],

      /** l'établissement sélectionné pour l'ajout */
      selectedEstablishment: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de la totalité des super établissements
        this.allSuperEstablisments = await this.service.getAll();
        // Récupère la liste des super établissements
        this.source = this.allSuperEstablisments.find((s) => s.id == this.entityId);
        // Récupère la liste des établissements
        let allEstablishments = await this.serviceEstablishment.getEstablishments();

        // Récupération de l'établissment principal
        if (this.source.mainEstablishment) {
          this.mainEstablishment = allEstablishments.find((e) => e.id == this.source.mainEstablishment);
        }

        // Parcours de tous les super établissements pour récupérer tous les ids d'établissments déjà associés
        let idsAssociates = [];
        this.allSuperEstablisments.forEach((s) => {
          if (s.id != this.source.id) {
            idsAssociates = idsAssociates.concat(s.establishments);
          }
        });
        // Récupération de tous les établissements qui n'ont pas déjà été associés
        this.allAssociableEstablishments = allEstablishments.filter((e) => !idsAssociates.includes(e.id));

        // Les établissements associés au super établissement sans l'établissement principal
        this.associateEstablishment = this.allAssociableEstablishments.filter((e) => 
          this.source.establishments.includes(e.id) 
          && this.source.mainEstablishment != e.id);

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.digitalName = this.source.digitalName;
      this.name = this.source.name;
      this.website = this.source.website;
    },

    /** Suppression de l'établissement de la liste des associés */
    removeAssociateEstablishment(entity) {
      this.associateEstablishment.splice(
        this.associateEstablishment.findIndex((e) => e.id == entity.id),
        1
      );
    },

    /** Ajoute un établissement à la liste */
    addEstablishment(entity) {
      if (!entity) return;

      this.associateEstablishment.push(entity);
      this.selectedEstablishment = null;
    },

    /** Sauvegarde d'un super établissement */
    async save() {

      // enregistrement 
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.digitalName = this.digitalName;
          entity.name = this.name;
          entity.mainEstablishment = this.mainEstablishment.id;
          entity.website = this.website;

          entity.establishments = [];
          entity.establishments.push(this.mainEstablishment.id);

          // Récupération de tous les ids des établissements associés
          let ids = this.associateEstablishment.map((e) => e.id);
          entity.establishments = entity.establishments.concat(ids);

          // création du super établissement
          await this.service.update(entity);
          
          this.disableAlertQuit();
          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement du status établissement : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }    
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },

  },
  computed: {
    /** retourne la liste des établissement non attribué à un super établissement */
    availableEstablishment() {
      let availables = [];

      let ids = this.associateEstablishment.map((e) => e.id);

      // si un établissement principal a été choisi, il faut le prendre en compte
      if (this.mainEstablishment) {
        availables = this.allAssociableEstablishments.filter((e) => !ids.includes(e.id) && e.id != this.mainEstablishment.id);        
      } else {
        availables = this.allAssociableEstablishments.filter((e) => !ids.includes(e.id));        
      }

      return availables;
    },

    /** Retourne la liste de tous les établissement disponibles pour un établissement principal */
    availableMainEstablishments() {
      let availables = [];

      let ids = this.associateEstablishment.map((e) => e.id);

      availables = this.allAssociableEstablishments.filter((e) => !ids.includes(e.id));

      return availables;
    },

    completed() {
      if (!this.digitalName) return false;
      if (!this.name) return false;
      if (!this.mainEstablishment) return false;

      return true;
    },

    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }   

      return changed;
    },
    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(this.digitalName.trim(), this.source.digitalName)) {
        changed = true;
      }
      if (!areTheSame(this.name, this.source.name)) {
        changed = true;
      }
      
      if (!areTheSame(this.website, this.source.website)) {
        changed = true;
      }
      
      if (this.mainEstablishment.id != this.source.mainEstablishment) {
        changed = true;
      }

      if (this.entitiesAssociateHasChanged) {
        changed = true;
      }

      return changed;
    },

    /** retourne true si des établissements ont été ajoutés */
    entitiesAssociateHasChanged() {
      let changed = false;

      // A-t-on ajouté des établissements ?
      this.associateEstablishment.forEach((ea) => {
        let found = this.source.establishments.find((eb) => ea.id == eb);
        if (!found) {
          changed = true;
        }
      });

      /** Pour éviter d'analyser la fonction inverse précédente, si on a déjà détecté du changement, on le retourne */
      if (changed) {
        return true;
      }

      // A-t-on supprimé des établissements ?
      this.source.establishments.forEach((ea) => {
        if (ea != this.source.mainEstablishment) {
          let found = this.associateEstablishment.find((eb) => ea == eb.id);
          if (!found) {
            changed = true;
          }
        }
      });

      return changed;
    },    
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new SuperEstablishmentService(
      this.$api.getSuperEstablishmentApi()
    );
    this.serviceEstablishment = new EstablishmentsService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>